import React, { useState, useEffect } from 'react';
import { Button, MenuItem, FormControl, InputLabel, Select, Typography, Grid, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Paper, TextField } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { invokeApig } from '../libs/awsLib';

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  accessTitle: {
    marginTop: '40px',
  },
  buttonGoBack: {
    marginTop: '15px',
  },
	btnDelete: {
		backgroundColor: '#f44336',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#f44336', 0.2),
      color: '#fff',
    },
	},
}));

function OrganizationDeleteForm(props) {
  const classes = useStyles();
  const [textValidate, setTextValidate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  const listUsers = () => {
    return invokeApig({
      path: "/workmail/user/list",
      method: "POST",
      body: {
        'organizationId': props.organization.OrganizationId
      }
    });
  };
  
  const deleteOrganization = () => {
    return invokeApig({
      path:  `/workmail/organization/${props.organization.OrganizationId}`,
      method: "DELETE"
    });
  };
  const handleChangeValidate = (event) => {
    setTextValidate(event.target.value);
    setIsError(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (textValidate !== 'eliminar') {
      setMessage('Debes ingresar el texto para confirmar la eliminación');
      setIsError(true);
      setIsLoading(false);
      return;
    }
    const res_users = await listUsers();
    if (res_users.status !== 'OK' || res_users.results.length !== 0) {
      setIsError(true);
      setMessage('Primero debes eliminar todas las casillas de correo');
      setIsLoading(false);
      return;
    }
    const response = await deleteOrganization();
    if (response.status === 'OK') {
      setMessage('La organización se ha eliminado correctamente');
      if (props.onClose) {
        props.onClose(true);
      } else {
        props.history.push('/');
      }
    } else {
      let msg = 'Error al eliminar la organización, por favor intenta nuevamente o contacta a Soporte';
      setMessage(msg);
      setIsError(true);
    }
    setIsLoading(false);
  };

  const handleGoBack = (event) => {
    event.preventDefault();
    if (props.onClose) {
      props.onClose();
    } else {
      props.history.push('/');
    }
  }

  return (
    <>
      <Dialog open={props.show} onClose={handleGoBack} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Eliminar la organización</DialogTitle>
        <DialogContent>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                Dominio a eliminar
              </Grid>
              <Grid item xs={12} sm={6}>
                <b>{props.organization.DefaultMailDomain}</b>
              </Grid>
            </Grid>
          </Paper>
          <Alert severity="info">
            <Typography>
              Recuerda que para eliminar una configuración de dominio primero debes eliminar todas las casillas de correo electrónico.
            </Typography>
            <Typography>
              <b>¿Est&aacute;s seguro que quieres eliminar esta configuraci&oacute;n?</b>
            </Typography>
          </Alert>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Validación"
                helperText={`Para eliminar la configuración del dominio por favor ingresa el texto 'eliminar'`}
                name="textValidate"
                value={textValidate}
                onChange={handleChangeValidate}
                required
                fullWidth
                className={classes.textValidate}
              />
            </Grid>
          </Grid>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleGoBack}
            disabled={isLoading}
          >
            Volver
          </Button>
          
          <Button
            type="button"
            variant="contained"
            className={classes.btnDelete}
            onClick={handleSubmit}
            disabled={isLoading || isError}
          >
            {
              (isLoading) ?
              <CircularProgress />
              :
              'Eliminar organización'
            }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrganizationDeleteForm;