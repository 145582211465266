import React, { Fragment, useEffect, useState } from 'react';
import {
  Button, Typography, Grid, CircularProgress, TextField, Chip,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  Paper,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core';
import InputPassword from '../../components/InputPassword';
import * as Icons from '@material-ui/icons';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { invokeApig } from '../../libs/awsLib';
import {validateText, validateTextAndWhite, validatePassword} from '../../libs/validation';
import NeokodeButton from '../../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  attribute: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  buttonsDialogContainer: {
    padding: '24px',
  },
  btnLoading: {
    marginRight: '15px',
  }
}));

function GroupForm(props) {
  const classes = useStyles();
  const isEdit = !!props.group;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [group, setGroup] = useState({
    name: '',
    mail: '',
  });
  const [openEditGroupDialog, setOpenEditGroupDialog] = useState(props.show);
  
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    setOpenEditGroupDialog(props.show);
  }, [props.show]);

  useEffect(() => {
    if (props.group && props.group.Id) {
      const edit_group = {
        id: props.group.Id,
        name: props.group.Name,
        mail: props.group.Email.split('@')[0],
      }
      setGroup(edit_group);
    }
  }, [props.group]);

  const getErrors = () => {
    let message = undefined;
    if (!isEdit && !validateText(group.name)) {
      message = 'Debe ingresar el grupo correctamente, sólo letras.';
    } else {
    if (!validateText(group.mail)) {
      message = 'Debe ingresar el email correctamente, sólo letras.';
    }}
    return message;
  }

  const handleSaveGroup = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const errorMsg = getErrors();
      if (errorMsg) {
        setMessage(errorMsg);
        setIsError(true);
        setIsLoading(false);
        return;
      }
      const request = {
        ...group,
        organizationId: props.organization.OrganizationId,
        id: isEdit ? props.group.Id : undefined
      }
      const response = await invokeApig({
        path: "/workmail/group",
        method: isEdit ? "PUT" : "POST",
        body: request
      });
      if (response.status === "OK") {
        setMessageToast(`Se ha ${isEdit ? 'modificado' : 'creado'} el grupo correctamente`);
        setOpenToast(true);
        handleCloseEditGroupDialog();
      } else {
        setMessage(`No hemos podido ${isEdit ? 'modificar' : 'crear'} el grupo, por favor intenta más tarde o contacta a Soporte`);
        setIsError(true);
      }
    } catch(error) {
      setMessage(`Error al ${isEdit ? 'modificar' : 'crear'} el grupo, por favor intenta más tarde o contacta a Soporte`);
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGroup({ ...group, [name]: value });
    setIsError(false);
  };
  const handleChangeName = (event) => {
    const { name, value } = event.target;
    setGroup({ ...group, [name]: value, mail: value.toLowerCase() });
    setIsError(false);
  }
  
  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const handleCloseEditGroupDialog = () => {
    props.onClose();
  }

  const renderEditGroupForm = () => {
    return (
      (isEdit && isLoading && !group.id) ?
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Skeleton variant="text" height={64} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" height={64} />
        </Grid>
      </Grid>
      :
      <>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              {
                isEdit ?
                <TextField label="Nombre" value={group.name || ''} defaultValue={group.name} InputProps={{ readOnly: true }} InputLabelProps={{ shrink: true }} fullWidth className={classes.formControl} />
                :
                <TextField label="Nombre" name="name" value={group.name} onChange={handleChangeName} required fullWidth className={classes.formControl} autoComplete='new-password' />
              }
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth required className={classes.formControl}>
                <InputLabel htmlFor="mail" shrink={!!group.mail}>Email</InputLabel>
                <Input id="mail" name="mail" value={group.mail} onChange={handleChange} autoComplete='new-password'
                  endAdornment={<InputAdornment position="end">@{props.organization.DefaultMailDomain}</InputAdornment>}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }

  const renderEditGroupDialog = () => {
    return (
      <Dialog open={openEditGroupDialog} onClose={handleCloseEditGroupDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{isEdit ? 'Modificar grupo' : 'Crear grupo'}</DialogTitle>
        <DialogContent>
          {renderEditGroupForm()}
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseEditGroupDialog}
            disabled={isLoading}
          >
            Cerrar ventana
          </Button>
          <NeokodeButton
            variant="contained"
            onClick={handleSaveGroup}
            disabled={isLoading}
          >
            {
              (isLoading && (!isEdit || (isEdit && group.id))) ?
              <>
                <CircularProgress size={20} className={classes.btnLoading} />{isEdit ? '...' : 'creando grupo...'}
              </>
              :
              ( isEdit ? 'Guardar cambios' : 'Crear grupo' )
            }
          </NeokodeButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
      {renderEditGroupDialog()}
    </div>
  );
}

export default GroupForm;