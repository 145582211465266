import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Button, Grid } from '@material-ui/core';
import NeokodeButton from '../components/NeokodeButton';
import OrganizationForm from '../containers/OrganizationForm';

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - 94px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    textAlign: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: theme.palette.error.main,
  },
}));

function NoOrganization(props) {
  const classes = useStyles();
  const [showCreateOrganization, setShowCreateOrganization] = useState(false);
	
  const handleShowCreateOrganization = async () => {
		setShowCreateOrganization(true);
	}

	const handleCloseCreateOrganization = async (success) => {
		setShowCreateOrganization(false);
		if (success) {
			if (props.onCloseSuccess) {
        props.onCloseSuccess();
      }
		}
	}

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h4" component="h1">
          Primero asocia un dominio
        </Typography>
        <Typography variant="body1">
          Antes de crear usuarios tienes que asociar un dominio
        </Typography>
        <NeokodeButton variant="contained" color="primary" onClick={handleShowCreateOrganization}>
          Configurar dominio
        </NeokodeButton>
        {
          showCreateOrganization &&
          <OrganizationForm show={showCreateOrganization} onClose={handleCloseCreateOrganization} />
        }
      </Paper>
    </div>
  );
}

export default NoOrganization;