import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import config from './config';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';

Amplify.configure(config.cognito);

export default createBrowserHistory({});
config.ga_analytics && ReactGA.initialize(config.ga_analytics);

const hist = createBrowserHistory();
hist.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();