import React, { useState, useEffect } from 'react';
import { Button, MenuItem, FormControl, InputLabel, Select, Typography, Grid, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Paper } from '@material-ui/core';
import NeokodeButton from '../components/NeokodeButton';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { invokeApig } from '../libs/awsLib';

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  accessTitle: {
    marginTop: '40px',
  },
  buttonGoBack: {
    marginTop: '15px',
  },
}));

function OrganizationForm(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [domains, setDomains] = useState([]);
  const [domain, setDomain] = useState({});

  useEffect(() => {
    updateDomains();
  }, []);

  const updateDomains = async () => {
    try {
      const response = await findDomains();
      if (response.status === 0) {
        setDomains(response.payload);
      } else {
        setIsError(true);
        setMessage('No pudimos cargar los dominios disponiblers');
      }
    } catch (error) {
      console.error('Error al obtener los datos del usuario: ', error);
    }
  }

  const findDomains = () => {
    return invokeApig({
      app: 'domain',
      path: "/domain/hostedzone/find",
      method: "POST",
      body: {}
    });
  };
  
  const createOrganization = (organization) => {
    return invokeApig({
      path: "/workmail/organization",
      method: "POST",
      body: {
        domain: organization.name
      }
    });
  };

  const handleChange = (event) => {
    setDomain(event.target.value);
  }

  const validate = () => {
    let message = undefined;
    if (!domain || !domain.id || !domain.name) {
      message = 'Debes seleccionar un dominio';
    }
    return message;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const errorMsg = validate();
    if (errorMsg) {
      setMessage(errorMsg);
      setIsError(true);
      return;
    }
    const response = await createOrganization(domain);
    if (response.status === 'OK') {
      setMessage('La organización se ha creado correctamente');
      if (props.onClose) {
        props.onClose(true);
      } else {
        props.history.push('/');
      }
    } else {
      let msg = 'Error al crear la organización, por favor intenta nuevamente o contactar a Soporte';
      setMessage(msg);
      setIsError(true);
    }
    setIsLoading(false);
  };

  const handleGoBack = (event) => {
    event.preventDefault();
    if (props.onClose) {
      props.onClose();
    } else {
      props.history.push('/');
    }
  }

  return (
    <>
      <Dialog open={props.show} onClose={handleGoBack} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Agregar una organización</DialogTitle>
        <DialogContent>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="p">Selecciona un dominio</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Dominio</InputLabel>
                  <Select name='domain' value={domain} onChange={handleChange}>
                    {
                      domains.map((dom, index) => {
                        return (
                          <MenuItem key={index} value={dom}>{dom.name}</MenuItem>
                        );
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleGoBack}
            disabled={isLoading}
          >
            Volver
          </Button>
          
          <NeokodeButton
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {
              isLoading ?
              <CircularProgress />
              :
              'Crear organización'
            }
          </NeokodeButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrganizationForm;