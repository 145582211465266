import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import {
  Typography, IconButton, Grid, Paper, TextField, Button,
  Snackbar,
  Tooltip,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DataGrid, esES } from '@material-ui/data-grid';
import * as Icons from '@material-ui/icons';
import NoRowsOverlay from '../components/NoRowsOverlay';
import QuickSearchToolbar from '../components/QuickSearchToolbar';
import moment from 'moment';
import { invokeApig } from '../libs/awsLib';
import NoOrganization from '../components/NoOrganization';
import LoadingPage from './LoadingPage';
import GroupForm from '../containers/group/GroupForm';
import GroupConfirmForm from '../containers/group/GroupConfirmForm';
//import { fixInstance, getPlanName, getState } from '../libs/instanceHelper';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
    margin: '15px 0',
  },
  gridMaxMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  deleteButton: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: darken('#f44336', 0.2),
    },
  },
  enableButton: {
    backgroundColor: '#16cd6f',
    '&:hover': {
      backgroundColor: darken('#16cd6f'),
    },
  },
  disableButton: {
    backgroundColor: '#ff9800',
    '&:hover': {
      backgroundColor: darken('#ff9800'),
    },
  },
  textValidate: {
    margin: '15px 0',
  },
  iconDisabled: {
    color: '#ff9800',
  },
  iconEdit: {
    color: '#2196f3',
  },
  iconDelete: {
    color: '#f44336',
  },
  iconEnable: {
    color: '#16cd6f',
  },
  buttonsDialogContainer: {
    padding: '10px 25px',
  },
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function GroupsPage(props) {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const organizationId = searchParams.get('organizationId');
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  const [group, setGroup] = useState({});
  const [isCreateGroup, setIsCreateGroup] = useState(false);
  const [isEditGroup, setIsEditGroup] = useState(false);
  const [isDisableGroup, setIsDisableGroup] = useState(false);
  const [isEnableGroup, setIsEnableGroup] = useState(false);
  const [isDeleteGroup, setIsDeleteGroup] = useState(false);
  const [disableParams, setDisableParams] = useState({});
  const [enableParams, setEnableParams] = useState({});
  const [deleteParams, setDeleteParams] = useState({});

  const [groups, setGroups] = useState([]);
  const [rows, setRows] = useState(groups);
  const [searchText, setSearchText] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = async () => {
    try {
      setIsLoading(true);
      setGroups([]);
      setRows([]);
      setSearchText('');
      const resOrganizations = await invokeApig({
        path: "/workmail/organization/find",
        method: "POST",
        body: {}
      });
      if (resOrganizations.status === 'OK') {
        const orgs = resOrganizations.results.filter(org => org.State !== 'Deleted');
        setOrganizations(orgs);
        if (orgs.length > 0) {
          if (orgs.length === 1) {
            setOrganization(orgs[0]);
            const response = await invokeApig({
              path: "/workmail/group/list",
              method: "POST",
              body: {
                'organizationId': orgs[0].OrganizationId,
              }
            });
            if (response.status === 'OK') {
              setGroups(response.results);
              setRows(response.results);
            } else {
              setMessage('Error al obtener los grupos');
              setIsError(true);
            }
          } else {
            // validar si llega como parámetro
            let orgId = false;
            if (organization.OrganizationId) {
              orgId = organization.OrganizationId;
            } else {
              orgId = organizationId;
              organizations.map((org, index) => {
                if (organizationId === org.OrganizationId) {
                  setOrganization(org);
                }
              });
            }
            if (orgId) {
              const response = await invokeApig({
                path: "/workmail/group/list",
                method: "POST",
                body: {
                  'organizationId': orgId,
                }
              });
              if (response.status === 'OK') {
                setGroups(response.results);
                setRows(response.results);
              } else {
                setMessage('Error al obtener los grupos');
                setIsError(true);
              }
            } else {
              setMessage('Selecciona un dominio');
              setIsError(true);
            }
          }
        }
      } else {
        setMessage('Error al obtener los dominios');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Error al obtener los grupos. Intenta nuevamente o consulta a Soporte.');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = groups.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  // Abrir/cerrar Dialog
  const resetState = (group) => {
    setGroup(group);
    setIsError(false);
    setMessage('');
  }
  const handleOpenCreateGroup = (group) => {
    resetState(group);
    setIsCreateGroup(true);
  }
  const handleOpenEditGroup = (group) => {
    resetState(group);
    setIsEditGroup(true);
  }
  const handleOpenDisableGroup = (group) => {
    resetState(group);
    setDisableParams({
      group: group,
      infoMessage: <p>Para <strong>eliminar</strong> el grupo primero debes <strong>deshabilitarlo</strong>.</p>,
      validateConfirm: false,
      confirmAction: '',
      confirmText: '',
      request: {
        path: "/workmail",
        method: "PUT",
        body: {
          organizationId: organization.OrganizationId,
          action: 'disable',
          entityId: group.Id
        }
      },
      errorMessage1: 'Se ha deshabilitado el grupo correctamente',
      errorMessage2: 'No hemos podido deshabilitar el grupo',
      errorMessage3: 'Error al deshabilitar el grupo',
      button: {
        color: classes.disableButton,
        text: 'Deshabilitar'
      }
    });
    setIsDisableGroup(true);
  }
  const handleOpenEnableGroup = (group) => {
    resetState(group);
    setEnableParams({
      group: group,
      infoMessage: <p>Habilita el grupo para que puedas acceder a los correos electrónicos nuevamente.</p>,
      validateConfirm: false,
      confirmAction: '',
      confirmText: '',
      request: {
        path: "/workmail",
        method: "PUT",
        body: {
          organizationId: organization.OrganizationId,
          action: 'enable',
          entityId: group.Id,
        }
      },
      errorMessage1: 'Se ha habilitado el grupo correctamente',
      errorMessage2: 'No hemos podido habilitar el grupo',
      errorMessage3: 'Error al habilitar el grupo',
      button: {
        color: classes.enableButton,
        text: 'Habilitar'
      },
      hasEmail: true,
    });
    setIsEnableGroup(true);
  }
  const handleOpenDeleteGroup = (group) => {
    resetState(group);
    setDeleteParams({
      group: group,
      infoMessage: <p>Si eliminas el grupo no podrás acceder a los correos nuevamente. Esta acción no se puede revertir.</p>,
      validateConfirm: true,
      confirmAction: 'eliminar',
      confirmText: 'eliminar',
      request: {
        path: "/workmail/group",
        method: "DELETE",
        body: {
          organizationId: organization.OrganizationId,
          action: 'delete',
          id: group.Id
        }
      },
      errorMessage1: 'Se ha eliminado el grupo correctamente',
      errorMessage2: 'No hemos podido eliminar el grupo',
      errorMessage3: 'Error al eliminar el grupo',
      button: {
        color: classes.deleteButton,
        text: 'Eliminar'
      }
    });
    setIsDeleteGroup(true);
  }
  const handleCloseCreateGroup = () => {
    resetState({});
    setIsCreateGroup(false);
    getGroups();
  }
  const handleCloseEditGroup = () => {
    resetState({});
    setIsEditGroup(false);
    getGroups();
  }
  const handleCloseDisableGroup = () => {
    resetState({});
    setIsDisableGroup(false);
    getGroups();
  }
  const handleCloseEnableGroup = () => {
    resetState({});
    setIsEnableGroup(false);
    getGroups();
  }
  const handleCloseDeleteGroup = () => {
    resetState({});
    setIsDeleteGroup(false);
    getGroups();
  }

  const stateFormatter = (cell, row) => {
    switch (cell) {
      case 'ENABLED':
        return <span className="badge badge-success" style={{ fontSize: 'inherit' }}>Activo</span>;
      case 'DISABLED':
        return <span className="badge badge-warning" style={{ fontSize: 'inherit' }}>Deshabilitado</span>;
      case 'DELETED':
        return <span className="badge badge-danger" style={{ fontSize: 'inherit' }}>Eliminado</span>;
      default:
        return 'Sin información';
    }
  }

  const handleCloseNoOrganization = () => {
    getGroups();
  }

  const columns = [
    {
      field: 'Name',
      headerName: 'Grupo',
      flex: 1,
      renderCell: (params) => (
        params.row.State === 'ENABLED' ?
        <RouterLink to={
          {
            pathname: `/group/members`,
            state: {
              organization: organization,
              group: params.row
            }
          }
          //`/group/members?organizationId=${organization.OrganizationId}&groupId=${params.row.Id}`
        }>
          {params.value}
        </RouterLink>
        :
        params.value
      )
    },
    {
      field: 'Email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'State',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => (
        stateFormatter(params.value)
      )
    },
    {
      field: 'EnabledDate',
      headerName: 'Fecha de habilitación',
      flex: 1,
      filterable: false,
      renderCell: (params) => (
        moment(params.value).format("DD-MM-YYYY HH:mm")
      )
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false,
      renderCell: (params) => (
        (params.row.State !== 'DELETED') &&
        <>
          {
            params.row.State === 'ENABLED' ?
              <>
                <Tooltip title="Deshabilitar grupo" enterDelay={500} leaveDelay={200}>
                  <IconButton onClick={() => handleOpenDisableGroup(params.row)} className={classes.iconDisabled}>
                    <Icons.RemoveCircle />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar grupo" enterDelay={500} leaveDelay={200}>
                  <IconButton onClick={() => handleOpenEditGroup(params.row)} className={classes.iconEdit}>
                    <Icons.Edit />
                  </IconButton>
                </Tooltip>
              </>
              :
              <>
                <Tooltip title="Activar grupo" enterDelay={500} leaveDelay={200}>
                  <IconButton onClick={() => handleOpenEnableGroup(params.row)} className={classes.iconEnable}>
                    <Icons.CheckCircle />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar grupo" enterDelay={500} leaveDelay={200}>
                  <IconButton onClick={() => handleOpenDeleteGroup(params.row)} className={classes.iconDelete}>
                    <Icons.Delete />
                  </IconButton>
                </Tooltip>
              </>
          }
        </>
      ),
    }
  ];

  if (organizations.length === 0) {
    return (
      isLoading ?
        <LoadingPage />
        :
        <NoOrganization onCloseSuccess={handleCloseNoOrganization} />
    );
  }

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
        {
          !!organization.OrganizationId ?
          <>
            <Paper className={classes.paper}>
              <Typography gutterBottom>
                Dominio: <b>{organization.DefaultMailDomain}</b>
              </Typography>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant="h4" gutterBottom>
                Administración de Grupos
              </Typography>
              <div style={{ height: '430px', width: '100%' }}>
                <DataGrid
                  getRowId={(row) => row.Id}
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  loading={isLoading}
                  components={{
                    NoRowsOverlay: NoRowsOverlay,
                    Toolbar: QuickSearchToolbar
                  }}
                  componentsProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(''),
                      onRefresh: () => getGroups(),
                      addButtonClick: handleOpenCreateGroup,
                      addButtonText: 'Nuevo grupo'
                    },
                  }}
                  localeText={esES.props.MuiDataGrid.localeText}
                />
              </div>
              {isCreateGroup && <GroupForm organization={organization} show={isCreateGroup} onClose={handleCloseCreateGroup} />}
              {isEditGroup && <GroupForm organization={organization} group={group} show={isEditGroup} onClose={handleCloseEditGroup} />}
              {isDisableGroup && <GroupConfirmForm params={disableParams} show={isDisableGroup} onClose={handleCloseDisableGroup} />}
              {isEnableGroup && <GroupConfirmForm organization={organization} params={enableParams} show={isEnableGroup} onClose={handleCloseEnableGroup} />}
              {isDeleteGroup && <GroupConfirmForm params={deleteParams} show={isDeleteGroup} onClose={handleCloseDeleteGroup} />}
            </Paper>
          </>
          :
          <Paper className={classes.paper}>
            Debes seleccionar un dominio
          </Paper>
        }
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default GroupsPage;
