import React, { Fragment, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import {
  Typography, IconButton, Grid, Paper, Button,
  Snackbar,
  List,
  ListItem,
  Divider,
  ListItemText,
  Checkbox,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as Icons from '@material-ui/icons';
import { invokeApig } from '../libs/awsLib';
import NeokodeButton from '../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
    margin: '15px 0',
  },
  gridMaxMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  domainInfo: {
    padding: '15px',
  },
  title: {
    fontSize: '22px',
    fontWeight: 600,
    padding: '15px',
  },
  members: {
    backgroundColor: theme.palette.background.paper,
    margin: '0 15px 0',
  },
  membersContainer: {
    width: '100%',
  },
  permissionsContainer: {
    width: '100%',
  },
  membersHeader: {
    backgroundColor: '#383838',
    color: '#ffffff',
  },
  membersHeaderButton: {
    color: '#ffffff',
  },
  permissions: {
    backgroundColor: theme.palette.background.paper,
    margin: '0 15px',
  },
  permissionsHeader: {
    backgroundColor: '#383838',
    color: '#ffffff',
  },
  permissionsHeaderButton: {
    color: '#ffffff',
  },
  deleteButton: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: darken('#f44336', 0.2),
    },
    color: '#ffffff',
  },
  warningMessage: {
    width: '100%',
  }
}));

function GroupMembersPage(props) {
  const classes = useStyles();
  const location = useLocation();
  const { organization, group } = location.state;
  const [isLoading, setIsLoading] = useState(true);

  const [members, setMembers] = useState([]);
  const [showDeleteMembers, setShowDeleteMembers] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoadingDeleteMember, setIsLoadingDeleteMember] = useState(false);
  const [isLoadingAddMember, setIsLoadingAddMember] = useState(false);
  
  const [permissions, setPermissions] = useState([]);
  const [showDeletePermissions, setShowDeletePermissions] = useState(false);
  const [usersPermission, setUserPermissions] = useState([]);
  const [searchTextPermission, setSearchTextPermission] = useState('');
  const [isLoadingDeletePermissions, setIsLoadingDeletePermissions] = useState(false);
  const [isLoadingAddPermissions, setIsLoadingAddPermissions] = useState(false);

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialogMembers, setOpenDialogMembers] = useState(false);
  const [openDialogDeleteMembers, setOpenDialogDeleteMembers] = useState(false);
  const [openDialogPermissions, setOpenDialogPermissions] = useState(false);
  const [openDialogDeletePermissions, setOpenDialogDeletePermissions] = useState(false);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    getUsers(true, true, true);
  }, []);

  const getUsers = async (users, members, permissions) => {
    try {
      setIsLoading(true);
      if (members) {
        setMembers([]);
        setSearchText('');
      }
      if (permissions) {
        setPermissions([]);
        setSearchTextPermission('');
      }
      const response = await invokeApig({
        path: "/workmail/group/find",
        method: "POST",
        body: {
          organizationId: organization.OrganizationId,
          id: group.Id,
          users: users,
          members: members,
          permissions: permissions,
        }
      });
      if (response.status === 'OK') {
        const newMembers = [], newPermissions = [], newUsers = [], newUsersPermissions = [];
        response.users.map((user, index) => {
          if (members) {
            if (response.members.indexOf(user.key) !== -1) {
              newMembers.push(user);
            } else {
              newUsers.push(user);
            }
          }
          if (permissions) {
            if (response.permissions.indexOf(user.key) !== -1) {
              newPermissions.push(user);
            } else {
              newUsersPermissions.push(user);
            }
          }
        })
        if (members) {
          setMembers(newMembers);
          setUsers(newUsers);
          setShowDeleteMembers(false);
        }
        if (permissions) {
          setPermissions(newPermissions);
          setUserPermissions(newUsersPermissions);
          setShowDeletePermissions(false);
        }
      } else {
        setMessage('Error al obtener los dominios');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Error al obtener los grupos. Intenta nuevamente o consulta a Soporte.');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleToggleUser = (userToToggle) => {
    const updatedUsers = users.map((user) => {
      if (user.key === userToToggle.key) {
        return { ...user, chosen: !user.chosen };
      }
      return user;
    });
    setUsers(updatedUsers);
  }
  const handleToggleMember = (memberToToggle) => {
    let show = false;
    const updatedMembers = members.map((member) => {
      if (member.key === memberToToggle.key) {
        show = show || !member.chosen;
        return { ...member, chosen: !member.chosen };
      }
      show = show || !member.chosen;
      return member;
    });
    setShowDeleteMembers(show);
    setMembers(updatedMembers);
  }
  const handleToggleUserPermission = (userToToggle) => {
    const updatedUsers = usersPermission.map((user) => {
      if (user.key === userToToggle.key) {
        return { ...user, chosen: !user.chosen };
      }
      return user;
    });
    setUserPermissions(updatedUsers);
  }
  const handleTogglePermission = (permissionToToggle) => {
    let show = false;
    const updatedPermissions = permissions.map((permission) => {
      if (permission.key === permissionToToggle.key) {
        show = show || !permission.chosen;
        return { ...permission, chosen: !permission.chosen };
      }
      show = show || !permission.chosen;
      return permission;
    });
    setShowDeletePermissions(show);
    setPermissions(updatedPermissions);
  }

  const handleSaveMembers = async() => {
    try {
      setIsLoadingAddMember(true);
      setMessage('');
      setIsError(false);
      const newMembers = users.reduce((allUsers, user) => {
        if (user.chosen) {
          allUsers.push({ action: 'add', memberId: user.key });
        }
        return allUsers;
        }, []);
      const response = await invokeApig({
        path: "/workmail/group/member",
        method: "PUT",
        body: {
          organizationId: organization.OrganizationId,
          groupId: group.Id,
          data: newMembers
        }
      });
      if (response.status === 'OK') {
        setMessageToast('Se han agregado los miembros correctamente');
        setOpenToast(true);
        handleCloseDialogMembers();
        getUsers(true, true, false);
      } else {
        setMessage('Error al agregar usuarios al grupo');
        setIsError(true);
      }
    } catch(e) {
      setMessage('Error al agregar miembros al grupo. Intenta nuevamente o comunícate con Soporte.');
      setIsError(true);
    }
    setIsLoadingAddMember(false);
  }
  const handleDeleteMembers = async() => {
    try {
      setIsLoadingDeleteMember(true);
      setMessage('');
      setIsError(false);
      const newMembers = members.reduce((allMembers, member) => {
        if (member.chosen) {
          allMembers.push({ action: 'remove', memberId: member.key });
        }
        return allMembers;
        }, []);
      const response = await invokeApig({
        path: "/workmail/group/member",
        method: "PUT",
        body: {
          organizationId: organization.OrganizationId,
          groupId: group.Id,
          data: newMembers
        }
      });
      if (response.status === 'OK') {
        setMessageToast('Se han eliminado los miembros correctamente');
        setOpenToast(true);
        handleCloseDialogDeleteMembers();
        getUsers(true, true, false);
      } else {
        setMessage('Error al eliminar usuarios al grupo');
        setIsError(true);
      }
    } catch(e) {
      setMessage('Error al eliminar miembros al grupo. Intenta nuevamente o comunícate con Soporte.');
      setIsError(true);
    }
    setIsLoadingDeleteMember(false);
  }
  const handleSavePermissions = async() => {
    try {
      setIsLoadingAddPermissions(true);
      setMessage('');
      setIsError(false);
      const newPermissions = usersPermission.reduce((allUsers, user) => {
        if (user.chosen) {
          allUsers.push({ action: 'add', userId: user.key });
        }
        return allUsers;
        }, []);
      const response = await invokeApig({
        path: "/workmail/group/permission",
        method: "PUT",
        body: {
          organizationId: organization.OrganizationId,
          groupId: group.Id,
          data: newPermissions
        }
      });
      if (response.status === 'OK') {
        setMessageToast('Se han agregado los miembros correctamente');
        setOpenToast(true);
        handleCloseDialogPermissions();
        getUsers(true, false, true);
      } else {
        setMessage('Error al agregar permisos al grupo');
        setIsError(true);
      }
    } catch(e) {
      setMessage('Error al agregar permisos al grupo. Intenta nuevamente o comunícate con Soporte.');
      setIsError(true);
    }
    setIsLoadingAddPermissions(false);
  }
  const handleDeletePermissions = async() => {
    try {
      setIsLoadingDeletePermissions(true);
      setMessage('');
      setIsError(false);
      const newDeletePermissions = permissions.reduce((allPermissions, permission) => {
        if (permission.chosen) {
          allPermissions.push({ action: 'remove', userId: permission.key });
        }
        return allPermissions;
        }, []);
      const response = await invokeApig({
        path: "/workmail/group/permission",
        method: "PUT",
        body: {
          organizationId: organization.OrganizationId,
          groupId: group.Id,
          data: newDeletePermissions
        }
      });
      if (response.status === 'OK') {
        setMessageToast('Se han eliminado los permisos correctamente');
        setOpenToast(true);
        handleCloseDialogDeletePermissions();
        getUsers(true, false, true);
      } else {
        setMessage('Error al eliminar permisos al grupo');
        setIsError(true);
      }
    } catch(e) {
      setMessage('Error al eliminar permisos al grupo. Intenta nuevamente o comunícate con Soporte.');
      setIsError(true);
    }
    setIsLoadingDeletePermissions(false);
  }
  const handleShowDialogMembers = () => {
    setIsError(false);
    setMessage('');
    setOpenDialogMembers(true);
  }
  const handleCloseDialogMembers = () => {
    setOpenDialogMembers(false);
  }
  const handleShowDialogDeleteMembers = () => {
    setIsError(false);
    setMessage('');
    setOpenDialogDeleteMembers(true);
  }
  const handleCloseDialogDeleteMembers = () => {
    setOpenDialogDeleteMembers(false);
  }
  const handleShowDialogPermissions = () => {
    setIsError(false);
    setMessage('');
    setOpenDialogPermissions(true);
  }
  const handleCloseDialogPermissions = () => {
    setOpenDialogPermissions(false);
  }
  const handleShowDialogDeletePermissions = () => {
    setIsError(false);
    setMessage('');
    setOpenDialogDeletePermissions(true);
  }
  const handleCloseDialogDeletePermissions = () => {
    setOpenDialogDeletePermissions(false);
  }
  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const renderDialogMembers = () => {
    return (
      <Dialog open={openDialogMembers} onClose={handleCloseDialogMembers} fullWidth={true} maxWidth={'sm'} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Agregar miembros</DialogTitle>
        <DialogContent>
          <List dense className={classes.root}>
            {
              users.length === 0 ?
              <ListItem>
                <Alert severity='warning' className={classes.warningMessage}>
                  No hay usuarios disponibles para agregar
                </Alert>
              </ListItem>
              :
              users.map((user, index) => {
                return (
                  <ListItem key={index} button>
                    <ListItemText id={user.Id} primary={`${user.description}`} secondary={user.title} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleToggleUser(user)}
                        checked={user.chosen}
                        inputProps={{ 'aria-labelledby': user.key }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
            }
          </List>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button type="button" variant="contained" className={classes.button} onClick={handleCloseDialogMembers} disabled={isLoadingAddMember}>
            Cerrar ventana
          </Button>
          {
            (users.length > 0) &&
            <NeokodeButton variant="contained" onClick={handleSaveMembers} disabled={isLoadingAddMember}>
              {
                isLoadingAddMember ?
                <>
                  <CircularProgress size={20} className={classes.btnLoading} /> {'agregando miembros...'}
                </>
                :
                'Agregar miembros'
              }
            </NeokodeButton>
          }
        </DialogActions>
      </Dialog>
    );
  }

  const renderDialogDeleteMembers = () => {
    const membersToDelete = members.filter((member) => member.chosen);
    return (
      <Dialog open={openDialogDeleteMembers} onClose={handleCloseDialogDeleteMembers} fullWidth={true} maxWidth={'sm'} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Eliminar miembros</DialogTitle>
        <DialogContent>
          <List dense className={classes.root}>
            {
              membersToDelete.map((member, index) => {
                return (
                  <ListItem key={index} button>
                    <ListItemText primary={`${member.description}`} secondary={member.title} />
                  </ListItem>
                );
              })
            }
          </List>
          <Alert severity='info'>
            <Typography>Si eliminas los miembros seleccionados no recibirán más correos electrónicos que se envíen al grupo.</Typography>
            <Typography><b>¿Estás seguro que quiere eliminar estos miembros del grupo?</b></Typography>
          </Alert>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button type="button" variant="contained" className={classes.button} onClick={handleCloseDialogDeleteMembers} disabled={isLoadingDeleteMember}>
            Cerrar ventana
          </Button>
          {
            (membersToDelete.length > 0) &&
            <Button variant="contained" className={classes.deleteButton} onClick={handleDeleteMembers} disabled={isLoadingDeleteMember}>
              {
                isLoadingDeleteMember ?
                <>
                  <CircularProgress size={20} className={classes.btnLoading} /> {'eliminando miembros...'}
                </>
                :
                'Eliminar miembros'
              }
            </Button>
          }
        </DialogActions>
      </Dialog>
    );
  }

  const renderDialogPermissions = () => {
    return (
      <Dialog open={openDialogPermissions} onClose={handleCloseDialogPermissions} fullWidth={true} maxWidth={'sm'} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Agregar permiso</DialogTitle>
        <DialogContent>
          {
            (usersPermission.length > 0) &&
            <Alert severity='info'>
              Esta opción te permite agregar usuarios para enviar correos electrónicos a nombre del grupo sin mostrar el remitente.
            </Alert>
          }
          <List dense className={classes.root}>
            {
              usersPermission.length === 0 ?
              <ListItem>
                <Alert severity='warning' className={classes.warningMessage}>
                  No hay usuarios disponibles para agregar permisos
                </Alert>
              </ListItem>
              :
              usersPermission.map((user, index) => {
                return (
                  <ListItem key={index} button>
                    <ListItemText id={user.Id} primary={`${user.description}`} secondary={user.title} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleToggleUserPermission(user)}
                        checked={user.chosen}
                        inputProps={{ 'aria-labelledby': user.key }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
            }
          </List>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button type="button" variant="contained" className={classes.button} onClick={handleCloseDialogPermissions} disabled={isLoadingAddPermissions}>
            Cerrar ventana
          </Button>
          {
            (usersPermission.length > 0) &&
            <NeokodeButton variant="contained" onClick={handleSavePermissions} disabled={isLoadingAddPermissions}>
              {
                isLoadingAddPermissions ?
                <>
                  <CircularProgress size={20} className={classes.btnLoading} /> {'agregando permisos...'}
                </>
                :
                'Agregar permisos'
              }
            </NeokodeButton>
          }
        </DialogActions>
      </Dialog>
    );
  }

  const renderDialogDeletePermissions = () => {
    const permissionToDelete = permissions.filter((permission) => permission.chosen);
    return (
      <Dialog open={openDialogDeletePermissions} onClose={handleCloseDialogDeletePermissions} fullWidth={true} maxWidth={'sm'} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Eliminar permisos</DialogTitle>
        <DialogContent>
          <List dense className={classes.root}>
            {
              permissionToDelete.map((permission, index) => {
                return (
                  <ListItem key={index} button>
                    <ListItemText primary={`${permission.description}`} secondary={permission.title} />
                  </ListItem>
                );
              })
            }
          </List>
          <Alert severity='info'>
            <Typography>Si eliminas los permisos de los usuarios seleccionados no podrán enviar correos a nombre del grupo.</Typography>
            <Typography><b>¿Estás seguro que quiere eliminar el permiso para estos usuarios?</b></Typography>
          </Alert>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button type="button" variant="contained" className={classes.button} onClick={handleCloseDialogDeletePermissions} disabled={isLoadingDeleteMember}>
            Cerrar ventana
          </Button>
          {
            (permissionToDelete.length > 0) &&
            <Button variant="contained" className={classes.deleteButton} onClick={handleDeletePermissions} disabled={isLoadingDeletePermissions}>
              {
                isLoadingDeletePermissions ?
                <>
                  <CircularProgress size={20} className={classes.btnLoading} /> {'eliminando permisos...'}
                </>
                :
                'Eliminar permisos'
              }
            </Button>
          }
        </DialogActions>
      </Dialog>
    );
  }

  const renderMembers = () => {
    return (
      <List className={classes.members}>
        <ListItem className={classes.membersHeader}>
          <Typography variant="h5">
            Miembros
          </Typography>
          <ListItemSecondaryAction>
            {
              showDeleteMembers &&
              <IconButton aria-label="Eliminar" onClick={handleShowDialogDeleteMembers} className={classes.membersHeaderButton}>
                <Icons.Delete />
              </IconButton>
            }
            <IconButton edge="end" aria-label="Agregar" onClick={handleShowDialogMembers} className={classes.membersHeaderButton}>
              <Icons.PersonAdd />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {
          isLoading ?
          <>
            <CircularProgress size={20} className={classes.btnLoading} /> {'cargando datos...'}
          </>
          :
          (
            members.length === 0 ?
            <ListItem>
              <ListItemText primary="Este grupo no tiene miembros" secondary="Puedes agregar presionando el botón disponible arriba" />
            </ListItem>
            :
            members.map((member, index) => {
              const labelId = member.key;
              return (
                <Fragment key={index}>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemText primary={member.description} secondary={member.title} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleToggleMember(member)}
                        checked={member.chosen}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Fragment>
              );
            })
          )
        }
      </List>
    );
  }

  const renderPermissions = () => {
    return (
      <List className={classes.permissions}>
        <ListItem className={classes.permissionsHeader}>
          <Typography variant="h5">
            Permisos
          </Typography>
          <ListItemSecondaryAction>
            {
              showDeletePermissions &&
              <IconButton aria-label="Eliminar" onClick={handleShowDialogDeletePermissions} className={classes.permissionsHeaderButton}>
                <Icons.Delete />
              </IconButton>
            }
            <IconButton edge="end" aria-label="Agregar" onClick={handleShowDialogPermissions} className={classes.permissionsHeaderButton}>
              <Icons.PersonAdd />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {
          isLoading ?
          <>
            <CircularProgress size={20} className={classes.btnLoading} /> {'cargando datos...'}
          </>
          :
          (
            permissions.length === 0 ?
            <ListItem>
              <ListItemText primary="Este grupo no tiene permisos a usuarios" secondary="Puedes agregar presionando el botón disponible arriba" />
            </ListItem>
            :
            permissions.map((permission, index) => {
              const labelId = permission.key;
              return (
                <Fragment key={index}>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemText primary={permission.description} secondary={permission.title} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleTogglePermission(permission)}
                        checked={permission.chosen}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Fragment>
              );
            })
          )
        }
      </List>
    );
  }

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
        <Paper className={classes.paper}>
          <Grid container className={classes.domainInfo}>
            <Grid item sm={10}>
              <Typography gutterBottom>
                Dominio: <b>{organization.DefaultMailDomain}</b>
              </Typography>
              <Typography gutterBottom>
                Grupo: <b>{group.Name} - {group.Email}</b>
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <Button variant="contained" to='/groups' component={RouterLink}>Volver a grupos</Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom className={classes.title}>
            Administración de miembros de grupo
          </Typography>
          <Grid container>
            <Grid item sm={6} className={classes.membersContainer}>
              {renderMembers()}
            </Grid>
            <Grid item sm={6} className={classes.permissionsContainer}>
              {renderPermissions()}
            </Grid>
          </Grid>
        </Paper>
        {openDialogMembers && renderDialogMembers()}
        {openDialogDeleteMembers && renderDialogDeleteMembers()}
        {openDialogPermissions && renderDialogPermissions()}
        {openDialogDeletePermissions && renderDialogDeletePermissions()}
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default GroupMembersPage;
