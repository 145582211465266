import React, { Fragment, useEffect, useState } from 'react';
import {
  Button, Typography, Grid, CircularProgress, TextField, Chip,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  Paper,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core';
import InputPassword from '../../components/InputPassword';
import * as Icons from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { invokeApig } from '../../libs/awsLib';
import {validateText, validateTextAndWhite, validatePassword} from '../../libs/validation';
import NeokodeButton from '../../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  userContainer: {
    padding: '15px',
    marginBottom: '15px',
  },
  attribute: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  buttonsDialogContainer: {
    padding: '24px',
  },
  btnLoading: {
    marginRight: '15px',
  }
}));

function GroupConfirmForm(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [textValidate, setTextValidate] = useState('');
  const [openDialog, setOpenDialog] = useState(props.show);
  
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    setOpenDialog(props.show);
  }, [props.show]);

  useEffect(() => {
    if (props.params.hasEmail) {
      setEmail(props.params.group.Name.toLowerCase());
    }
  }, [props.params.hasEmail]);

  const handleConfirmAction = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      if (props.params.validateConfirm && props.params.confirmText !== textValidate) {
        setMessage('Debes ingresar el texto para confirmar');
        setIsError(true);
        setIsLoading(false);
        return;
      }
      if (props.params.hasEmail && !validateText(email)) {
        setMessage('Debes ingresar el email correctamente, sólo letras');
        setIsError(true);
        setIsLoading(false);
        return;
      }
      const request = {
        ...props.params.request,
      }
      if (props.params.hasEmail) {
        request.body.email = `${email}@${props.organization.DefaultMailDomain}`;
      }
      const response = await invokeApig(request);
      if (response.status === "OK") {
        setMessageToast(props.params.errorMessage1);
        setOpenToast(true);
        setIsLoading(false);
        handleCloseDialog();
        return
      } else {
        setMessage(`${props.params.errorMessage2}, por favor intenta más tarde o contacta a Soporte`);
        setIsError(true);
      }
    } catch(error) {
      setMessage(`${props.params.errorMessage3}, por favor intenta más tarde o contacta a Soporte`);
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    setIsError(false);
  };
  const handleChangeValidate = (event) => {
    setTextValidate(event.target.value);
    setIsError(false);
  };
  
  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const handleCloseDialog = () => {
    props.onClose();
  }

  const renderGroup = () => {
    return (
      <Paper className={classes.userContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <b>Nombre</b>
          </Grid>
          <Grid item xs={12} sm={8}>
            {props.params.group.Name}
          </Grid>
          <Grid item xs={12} sm={4}>
            <b>Email</b>
          </Grid>
          <Grid item xs={12} sm={8}>
            {
              !!props.params.hasEmail ?
              <FormControl fullWidth required className={classes.formControl}>
                <Input id="email" name="email" value={email} onChange={handleChangeEmail} autoComplete='new-password'
                  endAdornment={<InputAdornment position="end">@{props.organization.DefaultMailDomain}</InputAdornment>}
                />
              </FormControl>
              :
              props.params.group.Email
            }
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const renderForm = () => {
    return (
      <>
        <Alert severity="info">
          {props.params.infoMessage}
          <p><b>¿Estás seguro que quieres continuar con la acción?</b></p>
        </Alert>
      </>
    );
  }

  const renderDialog = () => {
    return (
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          {renderGroup()}
          {renderForm()}
          {
            props.params.validateConfirm &&
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Validación"
                  helperText={`Para ${props.params.confirmAction} el usuario por favor ingresa el texto '${props.params.confirmText}'`}
                  name="textValidate"
                  value={textValidate}
                  onChange={handleChangeValidate}
                  required
                  fullWidth
                  className={classes.textValidate}
                />
              </Grid>
            </Grid>
          }
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseDialog}
            disabled={isLoading}
          >
            Cerra esta ventana
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmAction}
            className={props.params.button.color}
            disabled={isLoading}
          >
            {
              isLoading ?
              <>
              <CircularProgress size={20} className={classes.btnLoading} />procesando...
              </>
              :
              props.params.button.text
            }
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
      {renderDialog()}
    </div>
  );
}

export default GroupConfirmForm;