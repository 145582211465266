import React, { Fragment, useEffect, useState } from 'react';
import {
  Button, Typography, Grid, CircularProgress, TextField, Chip,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  Paper,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core';
import InputPassword from '../../components/InputPassword';
import * as Icons from '@material-ui/icons';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { invokeApig } from '../../libs/awsLib';
import {validateText, validateTextAndWhite, validatePassword, validateUsername, validateLocalPart, validatePersonName, validateDisplayName} from '../../libs/validation';
import NeokodeButton from '../../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  attribute: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  buttonsDialogContainer: {
    padding: '24px',
  },
  btnLoading: {
    marginRight: '15px',
  }
}));

function UserForm(props) {
  const classes = useStyles();
  const isEdit = !!props.user;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState({
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    displayName: '',
    status: '',
    password: '',
  });
  const [isFocused, setIsFocused] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(props.show);
  
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    setOpenEditUserDialog(props.show);
  }, [props.show]);

  useEffect(() => {
    if (props.user && props.user.Id) {
      getUser();
    }
  }, [props.user]);

  const getUser = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      setUser({});
      const response = await invokeApig({
        path: '/workmail/user/read',
        method: 'POST',
        body: {
          id: props.user.Id,
          organizationId: props.organization.OrganizationId
        }
      });
      if (response.status === 'OK') {
        const edit_user = {
          id: response.user.UserId,
          name: response.user.Name,
          email: response.user.Email.split('@')[0],
          firstName: response.user.FirstName,
          lastName: response.user.LastName,
          displayName: response.user.DisplayName,
          status: '',
          password: '',
        }
        setUser(edit_user);
      } else {
        setMessage('Error al obtener el detalle del usuario');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Error al obtener el detalle del usuario. Intenta nuevamente o consulta a Soporte.');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const getErrors = () => {
    const errors = {
      name: null,
      email: null,
      firstName: null,
      lastName: null,
      displayName: null,
      password: null
    };
    if (!isEdit && !validateUsername(user.name)) {
      errors.name = 'El nombre de usuario debe contener sólo letras, números, puntos, guiones o guiones bajos';
    } else
    if (!validateLocalPart(user.email)) {
      errors.email = 'El email no cumple con el formato requerido';
    } else
    if (user.firstName && !validatePersonName(user.firstName)) {
      errors.firstName = 'El nombre debe contener sólo letras, números, espacios y guiones';
    } else
    if (user.lastName && !validatePersonName(user.lastName)) {
      errors.lastName = 'El apellido debe contener sólo letras, números, espacios y guiones';
    } else
    if (!validateDisplayName(user.displayName)) {
      errors.displayName = 'El nombre para mostrar debe contener sólo letras, números, espacios y guiones';
    } else
    if (!isEdit && (!validatePassword(user.password) || !isValidPassword)) {
      errors.password = 'Debe ingresar una contraseña correcta, mínimo 8 caracteres y 3 de las siguientes condiciones: mayúscula, minúscula, número, símbolo como :;!@#$%^&*_.';
    }
    return Object.values(errors).filter(Boolean)[0];
  }

  const handleSaveUser = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const errorMsg = getErrors();
      if (errorMsg) {
        setMessage(errorMsg);
        setIsError(true);
        setIsLoading(false);
        return;
      }
      const request = {
        ...user,
        organizationId: props.organization.OrganizationId,
        id: isEdit ? props.user.Id : undefined
      }
      const response = await invokeApig({
        path: "/workmail/user",
        method: isEdit ? "PUT" : "POST",
        body: request
      });
      if (response.status === "OK") {
        setMessageToast(`Se ha ${isEdit ? 'modificado' : 'creado'} el usuario correctamente`);
        setOpenToast(true);
        handleCloseEditUserDialog();
      } else {
        setMessage(`No hemos podido ${isEdit ? 'modificar' : 'crear'} el usuario, por favor intenta más tarde o contacta a Soporte`);
        setIsError(true);
      }
    } catch(error) {
      setMessage(`Error al ${isEdit ? 'modificar' : 'crear'} el usuario, por favor intenta más tarde o contacta a Soporte`);
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
    setIsError(false);
  };
  const handleChangeName = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value, email: value.toLowerCase() });
    setIsError(false);
  }
  const concatDisplayName = (firstName, lastName) => {
    return (!firstName && !lastName) ? '' : `${firstName || ''} ${lastName || ''}`.trim();
  }
  const handleChangeFirstName = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value, displayName: concatDisplayName(value, user.lastName) });
    setIsError(false);
  }
  const handleChangeLastName = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value, displayName: concatDisplayName(user.firstName, value) });
    setIsError(false);
  }

  const handleChangePasswordValidate = (isValid) => {
    setIsValidPassword(isValid);
  }

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const handleCloseEditUserDialog = () => {
    props.onClose();
  }

  const renderEditUserForm = () => {
    return (
      (isEdit && isLoading && !user.id) ?
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Skeleton variant="text" height={64} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" height={64} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" height={64} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Skeleton variant="text" height={64} />
        </Grid>
      </Grid>
      :
      <>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              {
                isEdit ?
                <TextField label="Usuario" value={user.name || ''} defaultValue={user.name} InputProps={{ readOnly: true }} InputLabelProps={{ shrink: true }} fullWidth className={classes.formControl} />
                :
                <TextField label="Usuario" name="name" value={user.name} onChange={handleChangeName} required fullWidth className={classes.formControl} autoComplete='new-password' />
              }
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth required className={classes.formControl}>
                <InputLabel htmlFor="email" shrink={isFocused || !!user.email}>Email</InputLabel>
                <Input id="email" name="email" value={user.email} onChange={handleChange} autoComplete='new-password'
                  onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}
                  endAdornment={<InputAdornment position="end">@{props.organization.DefaultMailDomain}</InputAdornment>}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Nombre" name="firstName" value={user.firstName} onChange={handleChangeFirstName} fullWidth className={classes.formControl} autoComplete='new-password' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Apellido" name="lastName" value={user.lastName} onChange={handleChangeLastName} fullWidth className={classes.formControl} autoComplete='new-password' />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField label="Nombre para mostrar" name="displayName" value={user.displayName} onChange={handleChange} required fullWidth className={classes.formControl} autoComplete='new-password' />
            </Grid>
            {
              !isEdit &&
              <Grid item xs={12} sm={12}>
                <InputPassword label="Contraseña" name="password" value={user.password} onChange={handleChange} required fullWidth  className={classes.formControl} autoComplete="new-password" onValidate={handleChangePasswordValidate} />
              </Grid>
            }
          </Grid>
        </form>
      </>
    );
  }

  const renderEditUserDialog = () => {
    return (
      <Dialog open={openEditUserDialog} onClose={handleCloseEditUserDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{isEdit ? 'Modificar usuario' : 'Crear usuario'}</DialogTitle>
        <DialogContent>
          {renderEditUserForm()}
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseEditUserDialog}
            disabled={isLoading}
          >
            Cerrar ventana
          </Button>
          <NeokodeButton
            variant="contained"
            onClick={handleSaveUser}
            disabled={isLoading}
          >
            {
              (isLoading && (!isEdit || (isEdit && user.id))) ?
              <>
                <CircularProgress size={20} className={classes.btnLoading} />{isEdit ? '...' : 'creando usuario...'}
              </>
              :
              ( isEdit ? 'Guardar cambios' : 'Crear usuario' )
            }
          </NeokodeButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
      {renderEditUserDialog()}
    </div>
  );
}

export default UserForm;