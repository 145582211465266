export function validateEmail(text) {
  return /^[a-zA-Z]+[a-zA-Z0-9.\-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(text);
}
export function validateNumber(text) {
  var re = new RegExp('^[0-9]+$');
  return re.test(text);
}
export function validateText(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-ZáéíóóÁÉÍÓÚñÑ]{' + ini + ',' + end + '}$');
  return re.test(text);
}
export function validateTextAndWhite(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-ZáéíóóÁÉÍÓÚñÑ ]{' + ini + ',' + end + '}$');
  return re.test(text);
}
export function validatePhone(text) {
  return /^[+]{1}[0-9]{3,16}$/.test(text);
}
export function validatePassword(text) {
  const hasLength = text.length >= 8;
  const hasUpper = /[A-Z]/.test(text);
  const hasLowwer = /[a-z]/.test(text);
  const hasDigit = /[0-9]/.test(text);
  const hasSymbol = /[:;!@#$%^&*_.]/.test(text);
  const countSuccess = [hasUpper, hasLowwer, hasDigit, hasSymbol].filter(Boolean).length;
  return hasLength && countSuccess >= 3;
}
//funciones nuevas
export function validateUsername(name) {
  if (!name || name.length < 1 || name.length > 64) return false;
  if (name.startsWith('.') || name.endsWith('.')) return false;
  if (name.includes('..')) return false;
  return /^[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9]$/.test(name);
}
export function validateLocalPart(localPart) {
  if (!localPart || localPart.length > 64) return false;
  if (localPart.startsWith('.') || localPart.endsWith('.')) return false;
  if (localPart.includes('..')) return false;
  return /^[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9]$/.test(localPart);
}
export function validatePersonName(name) {
  if (!name) return true; // Son opcionales
  if (name.length > 64) return false;
  return /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s'-]+$/.test(name.trim());
}
export function validateDisplayName(name) {
  if (!name || name.length < 1 || name.length > 64) return false;
  return /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s'-]+$/.test(name.trim());
}
