const local = {
  ga_analytics: '',
  s3: {
    BUCKET: "files.mail.neokodelabs.com"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokodelabs.com"
  },
  apps: {
    admin: 'http://localhost:3001',
    domain: 'http://localhost:3011',
    support: 'http://localhost:3000',
    billing: 'http://localhost:3007',
  },
  cognito: {
    Auth: {
      cookieStorage: {
        domain: "localhost",
        secure: false,
      },
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_5VsTpB8bg',
      userPoolWebClientId: 'fdgu6arr80mql74bc1pciq7ga',
      identityPoolId: 'us-east-1:214df7a4-ba0a-4899-850b-42fe1654cf9d'
    }
  },
  terms: 'https://www.neokodelabs.com/terms',
  policy: 'https://www.neokodelabs.com/privacy'
};

const dev = {
  ga_analytics: '',
  s3: {
    BUCKET: "files.mail.neokodelabs.com"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokodelabs.com"
  },
  apps: {
    admin: 'https://admin.neokodelabs.com',
    domain: 'https://domain.neokodelabs.com',
    support: 'https://support.neokodelabs.com',
    billing: 'https://billing.neokodelabs.com',
  },
  cognito: {
    Auth: {
      cookieStorage: {
        domain: "neokodelabs.com",
        secure: true,
      },
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_5VsTpB8bg',
      userPoolWebClientId: 'fdgu6arr80mql74bc1pciq7ga',
      identityPoolId: 'us-east-1:214df7a4-ba0a-4899-850b-42fe1654cf9d'
    }
  },
  terms: 'http://neokodelabs.com/terms',
  policy: 'http://neokodelabs.com/policy'
};

const prod = {
  ga_analytics: '',
  s3: {
    BUCKET: "files.mail.neokode.cl"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokode.cl"
  },
  apps: {
    admin: 'https://admin.neokode.cl',
    domain: 'https://domain.neokode.cl',
    support: 'https://support.neokode.cl',
    billing: 'https://billing.neokode.cl',
  },
  cognito: {
    Auth: {
      cookieStorage: {
        domain: "neokode.cl",
        secure: true,
      },
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_eEusLaI2w',
      userPoolWebClientId: '2ek3t58ujs5e79sthtl4gm1rm2',
      identityPoolId: 'us-east-1:937bc5bd-616c-4be1-b522-cc325214693c'
    }
  },
  terms: 'https://www.neokode.cl/terms',
  policy: 'https://www.neokode.cl/policy'
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : process.env.REACT_APP_ENV === 'development'
    ? dev
    : local;

console.log("REACT_APP_ENV:" + process.env.REACT_APP_ENV);

export default {
  version: '1.2.1',
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
