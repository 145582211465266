import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import config from '../config';

const useStyles = makeStyles((theme) => ({
    footer: {
        width: '100%',
        position: 'sticky',
        bottom: 0,
        top: 'auto',
        zIndex: 4,
        marginTop: 'auto',
        backgroundColor: '#eee',
        color: '#222',
    },
    termsContainer: {
        padding: '0 20px',
        fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
        textAlign: 'left',
        fontSize: '12px',
        lineHeight: '30px',
        height: '30px',
        backgroundColor: '#f5f5f5',
        position: 'relative',
    },
    terms: {
        minWidth: '50px',
        textAlign: 'center',
        textDecoration: 'none',
        float: 'right',
        fontWeight: 400,
        color: '#111',
        fontSize: '12px',
        padding: '0 0.2em',
        '&:hover': {
            textDecoration: 'none',
        }
    },
    copyright: {
        margin: '0 auto',
        padding: '0 2em',
        fontWeight: 400,
        color: '#111',
        fontSize: '12px'
    }
}));

const Footer = () => {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <div className={classes.termsContainer}>
                <a href={config.terms} target="_blank" rel="noopener noreferrer" className={classes.terms}>Términos y condiciones</a>
                <span className={classes.terms}>{'|'}</span>
                <a href={config.policy} target="_blank" rel="noopener noreferrer" className={classes.terms}>Política de privacidad</a>
                <center className={classes.copyright}>
                    © Mail App por <a href="https://www.neokode.cl" target="_blank" rel="noopener noreferrer">Neokode</a>
                </center>
            </div>
        </footer>
    );
};

export default Footer;
