import React, { Fragment, useEffect, useState } from 'react';
import {
  Button, Typography, Grid, CircularProgress, TextField, Chip,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  Paper,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core';
import InputPassword from '../../components/InputPassword';
import * as Icons from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { invokeApig } from '../../libs/awsLib';
import {validateText, validateTextAndWhite, validatePassword} from '../../libs/validation';
import NeokodeButton from '../../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  attribute: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  buttonsDialogContainer: {
    padding: '24px',
  },
  btnLoading: {
    marginRight: '15px',
  }
}));

function UserChangePassForm(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState({
    email: props.user.Email,
    displayName: props.user.DisplayName,
    password:'',
  });
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(props.show);
  
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    setOpenDialog(props.show);
  }, [props.show]);

  const getErrors = () => {
    let message = undefined;
    if (!validatePassword(user.password) || !isValidPassword) {
      message = 'Debe ingresar una contraseña correcta, mínimo 8 caracteres y 3 de las siguientes condiciones: mayúscula, minúscula, número, símbolo como :;!@#$%^&*_.';
    }
    return message;
  }

  const handleChangePassword = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const errorMsg = getErrors();
      if (errorMsg) {
        setMessage(errorMsg);
        setIsError(true);
        setIsLoading(false);
        return;
      }
      const request = {
        newPassWord: user.password,
        userId: props.user.Id,
        organizationId: props.organization.OrganizationId
      }
      const response = await invokeApig({
        path: "/workmail/user/resetPassword",
        method: "PUT",
        body: request
      });
      if (response.status === "OK") {
        setMessageToast('Se ha cambiado la contraseña correctamente');
        setOpenToast(true);
        handleCloseDialog();
      } else {
        setMessage('No hemos podido cambiar la contraseña, por favor intenta más tarde o contacta a Soporte');
        setIsError(true);
      }
    } catch(error) {
      setMessage('Error al cambiar la contraseña, por favor intenta más tarde o contacta a Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
    setIsError(false);
  };
  
  const handleChangePasswordValidate = (isValid) => {
    setIsValidPassword(isValid);
  }

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const handleCloseDialog = () => {
    props.onClose();
  }

  const renderForm = () => {
    return (
      <>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField label="Email" defaultValue={user.email} InputProps={{ readOnly: true }} fullWidth className={classes.formControl} readOnly />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField label="Nombre" defaultValue={user.displayName} InputProps={{ readOnly: true }} fullWidth className={classes.formControl} readOnly />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputPassword label="Contraseña" name="password" value={user.password} onChange={handleChange} required fullWidth  className={classes.formControl} autoComplete="new-password" onValidate={handleChangePasswordValidate} />
            </Grid>
          </Grid>
        </form>
      </>
    );
  }

  const renderDialog = () => {
    return (
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cambiar contraseña</DialogTitle>
        <DialogContent>
          {renderForm()}
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseDialog}
            disabled={isLoading}
          >
            Cerrar ventana
          </Button>
          <NeokodeButton
            variant="contained"
            onClick={handleChangePassword}
            disabled={isLoading}
          >
            {
              isLoading ?
              <>
              <CircularProgress size={20} className={classes.btnLoading} />cambiando contraseña...
              </>
              :
              'Cambiar contraseña'
            }
          </NeokodeButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
      {renderDialog()}
    </div>
  );
}

export default UserChangePassForm;