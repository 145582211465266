import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import {
  Typography, Grid, Paper, Button,
} from '@material-ui/core';
import NeokodeButton from '../components/NeokodeButton';
import Alert from '@material-ui/lab/Alert';
import { invokeApig } from '../libs/awsLib';
import NoOrganization from '../components/NoOrganization';
import LoadingPage from './LoadingPage';
import { Skeleton } from '@material-ui/lab';
import OrganizationForm from '../containers/OrganizationForm';
import OrganizationDeleteForm from '../containers/OrganizationDeleteForm';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  gridMaxMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  deleteButton: {
    backgroundColor: '#f44336',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: darken('#f44336', 0.2),
    },
    margin: '15px',
  },
}));

function OrganizationsPage(props) {
  const classes = useStyles();
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessDelete, setIsSuccessDelete] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  const [showCreateOrganization, setShowCreateOrganization] = useState(false);
  const [showDeleteOrganization, setShowDeleteOrganization] = useState(false);

  useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = async () => {
    try {
      setIsLoading(true);
      setMessage('');
      setIsError(false);
      setOrganizations([]);
      const response = await invokeApig({
        path: "/workmail/organization/find",
        method: "POST",
      });
      if (response.status === 'OK') {
        const orgs = response.results.filter(org => (org.State !== 'Deleted' && org.State !== 'Deleting'));
        setOrganizations(orgs);
      } else {
        setMessage('Ocurrió un error al obtener las orzanizaciones. Intanta nuevamente o comunícate con Soporte');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Error al obtener las orzanizaciones. Intanta nuevamente o comunícate con Soporte');
      setIsError(true)
    }
    setIsLoading(false);
  }

  const handleShowCreateOrganization = async () => {
    setShowCreateOrganization(true);
  }

  const handleCloseCreateOrganization = async (success) => {
    setShowCreateOrganization(false);
    if (success) {
      setIsSuccess(true);
      getOrganizations();
    }
  }

  const handleShowDeleteOrganization = async (organization) => {
    setOrganization(organization);
    setShowDeleteOrganization(true);
  }

  const handleCloseDeleteOrganization = async (success) => {
    setShowDeleteOrganization(false);
    if (success) {
      setIsSuccessDelete(true);
      getOrganizations();
    }
  }

  const handleCloseNoOrganization = async() => {
    getOrganizations();
  }

  if (organizations.length === 0) {
    return (
      isLoading ?
        <LoadingPage />
        :
        <NoOrganization onCloseSuccess={handleCloseNoOrganization} />
    );
  }

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Administración de dominios
          </Typography>
          <Alert severity='info'>
            <Typography>
              Acá podrás administrar tus dominios para utilizarlos en tus casillas de correo.
            </Typography>
            <Typography>
              <b>Importante</b>: Para eliminar una configuración primero tendrás que eliminar todas las casillas de correo.
            </Typography>
          </Alert>
          <Grid container justifyContent="center" className={classes.organizationsContainer}>
            {
              isLoading ?
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Skeleton variant="text" height={78} />
                  <Skeleton variant="text" height={36} />
                </Grid>
                :
                (
                  isError ?
                    <Alert severity="error">
                      {message}
                    </Alert>
                    :
                    <div className={classes.organizations}>
                      <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                          {
                            organizations.length === 0 ?
                              <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                  <Typography>Comienza configurando un dominio</Typography>
                                </Paper>
                              </Grid>
                              :
                              organizations.map((organization, index) => {
                                const urlClient = `https://${organization.Alias}.awsapps.com/mail`;
                                return (
                                  <Grid key={index} item xs={12}>
                                    <Paper className={classes.paper}>
                                      <Typography>
                                        Dominio: <strong>{organization.DefaultMailDomain}</strong>
                                      </Typography>
                                      <Typography>
                                        URL: <strong>{urlClient}</strong>
                                      </Typography>
                                      <Typography component="div">
                                        <Button
                                          variant="contained"
                                          onClick={() => handleShowDeleteOrganization(organization)}
                                          className={classes.deleteButton}
                                        >
                                          Eliminar esta configuración
                                        </Button>
                                      </Typography>
                                    </Paper>
                                  </Grid>
                                );
                              })
                          }
                          {
                            showCreateOrganization &&
                            <OrganizationForm show={showCreateOrganization} onClose={handleCloseCreateOrganization} />
                          }
                          {
                            showDeleteOrganization &&
                            <OrganizationDeleteForm show={showDeleteOrganization} organization={organization} onClose={handleCloseDeleteOrganization} />
                          }
                          {
                            isSuccess &&
                            <Alert severity="success" className={classes.successAlert}>
                              Dominio asignado exitosamente, ahora puedes crear tus casillas de correo
                            </Alert>
                          }
                          {
                            isSuccessDelete &&
                            <Alert severity="success" className={classes.successAlert}>
                              Dominio eliminado exitosamente
                            </Alert>
                          }
                          <Grid item xs={12}>
                            <Paper className={classes.paper}>
                              <Typography component="div">
                                <NeokodeButton
                                  variant="contained"
                                  onClick={handleShowCreateOrganization}
                                >
                                  Configurar nuevo dominio
                                </NeokodeButton>
                              </Typography>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                )
            }
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default OrganizationsPage;
