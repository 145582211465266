import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { Grid, Card, CardContent, Typography, Avatar, Paper, CircularProgress, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import * as Icons from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import NeokodeButton from '../components/NeokodeButton';
import { Link as RouterLink } from 'react-router-dom';
import { invokeApig } from '../libs/awsLib';
import OrganizationForm from '../containers/OrganizationForm';

const useStyles = makeStyles((theme) => ({
	welcome: {
		marginTop: '15px',
	},
	userCard: {
		backgroundColor: '#383838',
		color: '#fff',
		textAlign: 'center',
	},
	avatar: {
		backgroundColor: '#00b31f',
		width: theme.spacing(7),
		height: theme.spacing(7),
		margin: 'auto'
	},
	avatarSkeleton: {
		margin: '0 auto'
	},
	section: {
		margin: theme.spacing(3, 0),
		marginBottom: theme.spacing(10),
		width: 'inherit',
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	gridMax: {
		[theme.breakpoints.up('md')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
	},
	text: {
		marginBlockEnd: '16px',
		marginBlockStart: '16px',
	},
	organizationsContainer: {
		margin: '0',
		width: '100%',
	},
	organizationsTitle: {
		textAlign: 'center',
	},
	organizations: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		marginTop: theme.spacing(2),
	},
	successAlert: {
		width: '100%'
	},
	btnAction: {
		margin: '0 10px'
	},
	btnDelete: {
		backgroundColor: '#f44336',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#f44336', 0.2),
      color: '#fff',
    },
	}
}));

function HomePage(props) {
	const classes = useStyles();
	const [userInfo, setUserInfo] = useState({});
	const [organizations, setOrganizations] = useState([]);
	const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
	const [showCreateOrganization, setShowCreateOrganization] = useState(false);
	const url_doc = 'https://www.neokode.cl/docs/saas?saas=emails';

	useEffect(() => {
		getUserInfo();
		getOrganizations();
	}, []);

	const getUserInfo = async () => {
		try {
			const userData = await Auth.currentAuthenticatedUser();
			setUserInfo(userData);
		} catch (error) {
			console.error('Error in getUserInfo: ', error);
		}
		setIsLoadingUserInfo(false);
	}

	const getOrganizations = async () => {
		try {
      setIsLoading(true);
			setMessage('');
			setIsError(false);
			const response = await invokeApig({
				path: "/workmail/organization/find",
				method: "POST",
			});
			if (response.status === 'OK') {
				const orgs = response.results.filter(org => org.State !== 'Deleted');
				setOrganizations(orgs);
			} else{
				setMessage('Ocurrió un error al obtener las orzanizaciones. Intanta nuevamente o comunícate con Soporte');
        setIsError(true);
			}
    } catch (error) {
			setMessage('Error al obtener las orzanizaciones. Intanta nuevamente o comunícate con Soporte');
      setIsError(true)
    }
		setIsLoading(false);
	}

	const handleShowCreateOrganization = async () => {
		setShowCreateOrganization(true);
	}

	const handleCloseCreateOrganization = async (success) => {
		setShowCreateOrganization(false);
		if (success) {
			setIsSuccess(true);
			getOrganizations();
		}
	}

	const handleShowDeleteOrganization = async () => {
		setShowDeleteOrganization(true);
	}

	const handleCloseDeleteOrganization = async (success) => {
		setShowDeleteOrganization(false);
		if (success) {
			setIsSuccess(true);
			getOrganizations();
		}
	}

	return (
		<Grid container spacing={2} className={classes.section} justifyContent="center">
			<Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMax}>
				<Grid container spacing={2}>

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Card className={classes.userCard}>
							<CardContent>
								{
									isLoadingUserInfo ?
										<div>
											<Skeleton variant="circle" width={56} height={56} className={classes.avatarSkeleton}/>
											<Skeleton variant="text" height={47} />
											<Skeleton variant="text" height={32} />
										</div>
										:
										(
											(userInfo && userInfo.attributes) &&
											<>
												<Avatar className={classes.avatar}>
													<Icons.AccountCircle />
												</Avatar>
												<Typography variant="h5" className={classes.welcome}>Bienvenido, <b>{userInfo.attributes.name} {userInfo.attributes.family_name}</b></Typography>
												<Typography variant="h6">{userInfo.attributes.email}</Typography>
											</>
										)
								}
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} sm={12} md={8} lg={8} className={classes.section2}>
						<Card className={classes.userCard2}>
							<CardContent>
								<Typography variant="h4" className={classes.welcome}><b>Mail App</b></Typography>
								<Typography className={classes.text}>
									Gestiona los correos electrínicos empresariales de forma segura y eficiente utilizando tu propio dominio.
								</Typography>
								<Typography className={classes.text}>
									Pasa al siguiente nivel con casillas de alto rendimiento, gran capacidad de almacenamiento, seguras y compatibles con Outlook.
								</Typography>
								<Typography className={classes.text}>
									Los <b>beneficios</b> del correo electr&oacute;nico empresarial:
								</Typography>
								<ul>
									<li><b>Seguridad</b>: Protege tus comunicaciones con la avanzada tecnolog&iacute;a de cirado y seguridad de Amazon.</li>
									<li><b>Accesibilidad en cualquier lugar</b>: Mantente conectado y gestiona tus correos electrónicos desde cualquier dispositivo, en cualquier momento.</li>
									<li><b>Compatible con Outlook</b>: Utiliza el cliente de correo m&aacute;s utilizado por las empresas.</li>
									<li><b>Gran capacidad de almacenamiento</b>: Olv&iacute;date de los l&iacute;mites de almacenamiento y da la bienvenida a la libertad con 50 GB de almacenamiento.</li>
								</ul>
								<Typography className={classes.text}>
									Mail App no solo le da credibilidad a tu negocio y mejora tu eficiencia sino que también se adapta perfectamente a tu flujo de trabajo existente.
								</Typography>
								<Typography className={classes.text}>
									Documentación: <strong><a href={url_doc} target="_blank" >{url_doc}</a></strong>
								</Typography>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4} className={classes.gridMax}>
						<Paper className={classes.paper}>
							<Typography variant="h4" className={classes.organizationsTitle}>Dominios</Typography>
							<Grid container justifyContent="center" className={classes.organizationsContainer}>
								{
									isLoading ?
										<Grid item xs={12} sm={12} md={12} lg={12} >
											<Skeleton variant="text" height={78} />
											<Skeleton variant="text" height={36} />
										</Grid>
										:
										(
											isError ?
											<Alert severity="error">
												{message}
											</Alert>
											:
											<div className={classes.organizations}>
												<Grid container spacing={1}>
													<Grid container item xs={12} spacing={3}>
														{
															organizations.length === 0 ?
															<Grid item xs={12}>
																<Paper className={classes.paper}>
																	<Typography>Comienza configurando un dominio</Typography>
																</Paper>
															</Grid>
															:
															organizations.map((organization, index) => {
																return (
																	<Grid key={index} item xs={12}>
																		<Paper className={classes.paper}>
																			<Typography>
																				Dominio: <strong>{organization.DefaultMailDomain}</strong>
																			</Typography>
																			<Typography component="div">
																				<Button
																					variant="contained"
																					color="info"
																					component={RouterLink}
																					to={"/users?organizationId=" + organization.OrganizationId}
																					className={classes.btnAction}
																				>
																					Usuarios
																				</Button>
																				<Button
																					variant="contained"
																					color="info"
																					component={RouterLink}
																					to={"/groups?organizationId=" + organization.OrganizationId}
																					className={classes.btnAction}
																				>
																					Grupos
																				</Button>
																			</Typography>
																		</Paper>
																	</Grid>
																);
															})
														}
														{
															showCreateOrganization &&
															<OrganizationForm show={showCreateOrganization} onClose={handleCloseCreateOrganization} />
														}
														{
															isSuccess &&
															<Alert severity="success" className={classes.successAlert}>
																Dominio asignado exitosamente, ahora puedes crear tus casillas de correo
															</Alert>
														}
														<Grid item xs={12}>
															<Paper className={classes.paper}>
																<Typography component="div">
																	<NeokodeButton
																		variant="contained"
																		onClick={handleShowCreateOrganization}
																	>
																		Configurar nuevo dominio
																	</NeokodeButton>
																</Typography>
															</Paper>
														</Grid>
													</Grid>
												</Grid>
											</div>
										)
								}
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
export default HomePage;