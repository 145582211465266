import React, { Component } from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import config from '../config';
import NeokodeButton from '../components/NeokodeButton';

export default class RedirectLogin extends Component {
  handleClick = (evt) => {
    evt.preventDefault();
    window.location = config.apps.admin + this.props.to;
  }
  render() {
    return (
      <Dialog open={true}>
        <DialogTitle id="form-dialog-title">No autorizado</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <p>
              No estás autorizado para ingresar, por favor ingrese nuevamente con tu usuario y contraseña
            </p>
          </Alert>
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <NeokodeButton
            type="button"
            variant="contained"
            onClick={this.handleClick}
          >
            Iniciar sesión
          </NeokodeButton>
        </DialogActions>
      </Dialog>
    );
  }
}
