import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import PageIcon from '@material-ui/icons/Lock';
import NeokodeButton from '../components/NeokodeButton';
import config from '../config';

const useStyles = makeStyles((theme) => ({
    root: {
        height: `calc(100vh - 94px)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
        textAlign: 'center',
    },
    icon: {
        fontSize: '5rem',
        color: theme.palette.error.main,
    },
}));

function NotAccessPage() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <PageIcon className={classes.icon} />
                <Typography variant="h4" component="h1">
                No tienes acceso a esta aplicación
                </Typography>
                <Typography variant="body1">
                    Debes iniciar sesión y tener los permisos necesarios
                </Typography>
                <NeokodeButton variant="contained" color="primary" href={config.apps.admin}>
                    Iniciar sesión
                </NeokodeButton>
            </Paper>
        </div>
    );
}

export default NotAccessPage;